const state = () => ({
  all: {}
})

// getters
const getters = {
  getManagedUser: state => {
    return { id: state.all.managed_user_id, title: state.all.managed_user_title }
  },
  getRole: state => {
    return state.all.role;
  },
  getUser: state => {
    const user = { ...state.all }
    delete user.managed_user_id
    delete user.managed_user_title
    return user
  }
}

// actions
const actions = {
  async setManagedUser(context, { id, title } = {}) {
    context.commit('SET_MANAGED_USER', { id, title })
  }
}

// mutations
const mutations = {
  CLEAR_ALL(state) {
    state.all = {}
  },
  SET_MANAGED_USER(state, { id = state.all.id, title = `${state.all.firstName} ${state.all.lastName}` } = {}) {
    state.all.managed_user_id = id
    state.all.managed_user_title = title
  },
  SET_USER(state, user) {
    state.all.id = user.id
    state.all.role = user.role
    state.all.firstName = user.firstName
    state.all.lastName = user.lastName
    state.all.age = user.age
    state.all.phone = user.phone
    state.all.email = user.email
    state.all.gender = user.gender
    state.all.token = user.token
    if (state.all.managed_user_id === undefined) {
      state.all.managed_user_id = state.all.id
      state.all.managed_user_title = `${state.all.firstName} ${state.all.lastName}`
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

