import { createRouter, createWebHistory } from 'vue-router'

export default App => {

  const authorisedBeforeRoute = async (to, _from, next) => {
    const { success, message, data } = await App.config.globalProperties.$http.user()
    if (success) {
      App.config.globalProperties.$store.commit('user/SET_USER', data)
      const { success, message, policy } = await App.config.globalProperties.$http.fillPolicy()
      next()
    } else {
      next({ name: 'login', query: { redirect: to.name } })
    }
  }

  // const onlyAdminBeforeRoute = async (to, _from, next) => {
  //   App.config.globalProperties.$store.getters['user/getRole'] === 'admin' ? next() : next({ name: '404' })
  // }

  return createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: (to, from, _savedPosition) => (to.path != from.path) ? { x: 0, y: 0 } : undefined,
    routes: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      },
      {
        path: '',
        component: () => import('@/views/MainLayout.vue'),
        beforeEnter: authorisedBeforeRoute,
        children: [
          {
            path: '/employees',
            name: 'employees',
            component: () => import('@/views/Employees.vue'),
            //beforeEnter: onlyAdminBeforeRoute
          },
          {
            path: '/hunting',
            name: 'hunting',
            component: () => import('@/views/Hunting.vue'),
            //beforeEnter: onlyAdminBeforeRoute
          },
          {
            path: '/companies',
            name: 'companies',
            component: () => import('@/views/Companies.vue')
          },
          {
            path: '/employee_hunting',
            name: 'employee_hunting',
            component: () => import('@/views/EmployeeHunting.vue')
          },
          {
            path: '/procedures',
            name: 'procedures',
            component: () => import('@/views/Procedures.vue')
          },
          {
            path: '/feedbacks',
            name: 'feedbacks',
            component: () => import('@/views/Feedbacks.vue')
          },
          {
            path: '/report_employees',
            name: 'report_employees',
            component: () => import('@/views/ReportEmployee.vue')
          },
          {
            path: '/report_sum_companies',
            name: 'report_sum_companies',
            component: () => import('@/views/ReportSumCompanies.vue')
          },
          {
            path: '/report_sum_procedures',
            name: 'report_sum_procedures',
            component: () => import('@/views/ReportSumProcedures.vue')
          },
          {
            path: '/report_sum_company_by_userid',
            name: 'report_sum_company_by_userid',
            component: () => import('@/views/ReportSumCompanyByUserid.vue')
          },
          {
            path: '/report_sum_procedure_by_userid',
            name: 'report_sum_procedure_by_userid',
            component: () => import('@/views/ReportSumProcedureByUserid.vue')
          },
          {
            path: '/search',
            name: 'search',
            component: () => import('@/views/Search.vue')
          }
        ]
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/NotFound.vue')
      },
      {
        path: '/:anyURI(.*)',
        redirect: '404'
      },
    ]
  })
}