<template>
  <nav class="menu-root">
    <div class="logo"><mo-logo /></div>
    <div>
      <div class="title"><div>Momonitoring</div></div>
      <div class="about">
        <div class="copylink" @click="copyurl">
          Реферальная ссылка
          <mo-icon name="copylink" />
        </div>
        <div>{{ user.email }}</div>
        <div>{{ user.firstName }} {{ user.lastName }}</div>
        <div>{{ user.phone }}</div>
        <div class="signout" @click="logout">
          <span>Выход</span>
          <mo-icon name="signout" />
        </div>
      </div>
    </div>
    <div v-if="policy['mainmenu_dicts'] " id="mainmenu_dicts">
      <div class="title"><div>Справочники</div></div>
      <router-link
        v-for="item in references"
        :to="{ name: item.name }"
        :key="item.name"
        isActive
      >
        <span>
          {{ item.title }}
        </span>
        <mo-icon name="chevronright" color="white" />
      </router-link>
    </div>

    <div v-if="policy['view_employee_admin']" id="view_employee_admin">
      <div class="title">
        <div>Просмотр сотрудника</div>
      </div>
      <div class="about" v-if="asUser.id !== user.id">
        <div>{{ asUser.title || "" }} ({{ asUser.id }})</div>
        <div class="close" @click="clearManagedUser">
          <span>Вернуться</span>
          <mo-icon name="close" />
        </div>
      </div>
      <router-link
        v-for="item in common_admin"
        :to="{ name: item.name }"
        :key="item.name"
        isActive
      >
        <span>
          {{ item.title }}
        </span>
        <mo-icon name="chevronright" color="white" />
      </router-link>
    </div>
    
    <div v-if="policy['view_employee_user']" id="view_employee_user">
      <div class="title">
        <div>Сотрудник</div>
      </div>
      <router-link
        v-for="item in common_user"
        :to="{ name: item.name }"
        :key="item.name"
        isActive
      >
        <span>
          {{ item.title }}
        </span>
        <mo-icon name="chevronright" color="white" />
      </router-link>
    </div>


    <div v-if="policy['mainmenu_reports']" id="mainmenu_reports">
      <div class="title"><div>Отчеты</div></div>
      <router-link
        v-for="item in reports"
        :to="{ name: item.name }"
        :key="item.name"
        isActive
      >
        <span>
          {{ item.title }}
        </span>
        <mo-icon name="chevronright" color="white" />
      </router-link>
    </div>

    <div>
      <div class="title"><div>Прочее</div></div>
      <router-link
        v-for="item in other"
        :to="{ name: item.name }"
        :key="item.name"
        isActive
      >
        <span>
          {{ item.title }}
        </span>
        <mo-icon name="chevronright" color="white" />
      </router-link>
    </div>
  </nav>
</template>

<script>
import { defineComponent } from "vue";

const references = [
  { title: "Сотрудники", name: "employees" },
  { title: "Потенциальные компании", name: "hunting" },
];
const commonAsAdmin = [
  { title: "Компании по сотруднику", name: "companies" },
  { title: "Потенциальные компании по сотруднику", name: "employee_hunting",},
  { title: "Процедуры по сотруднику", name: "procedures" },
  { title: "Отзывы по сотруднику", name: "feedbacks" },
];
const commonAsUser = [
  { title: "Компании", name: "companies" },
  { title: "Потенциальные компании", name: "employee_hunting" },
  { title: "Процедуры", name: "procedures" },
];
const reports = [
  { title: "Рейтинг сотрудников", name: "report_employees" },
  { title: "Прибыль по компаниям", name: "report_sum_companies" },
  { title: "Прибыль по процедурам", name: "report_sum_procedures" },
  { title: "Компании сотрудника", name: "report_sum_company_by_userid" },
  { title: "Процедуры сотрудника", name: "report_sum_procedure_by_userid" },
];
const other = [{ title: "Поиск", name: "search" }];

export default defineComponent({
  name: "moMenu",
  props: {},
  data() {
    return {
      role: this.$store.getters["user/getRole"],
      user: this.$store.getters["user/getUser"],
      policy: this.$store.getters["policy/getPolicy"],
      references,
      common_admin: commonAsAdmin,
      common_user : commonAsUser,
      reports,
      other,
    };
  },
  computed: {
    asUser() {
      return this.$store.getters["user/getManagedUser"];
    },
  },
  methods: {
    async copyurl() {
      const pref_url =
        process.env.VUE_APP_RELATED_HOST +
        `/?next_url__auth=required&next_url__url=` +
        encodeURIComponent(process.env.VUE_APP_API_END_POINT_URL) +
        `%2Fcompany%2Fattach%3Fmo_id%3D`;
      const { token } = this.$store.getters["user/getUser"];
      navigator.clipboard
        .writeText(pref_url + token)
        .then(() => {
          this.$notify({
            type: "success",
            text:
              "Ссылка скопирована в буфер!\n Для вставки используйте комбинацию клавиш ctrl+v",
          });
        })
        .catch((error) => {
          this.$notify({ type: "error", text: "Ошибка копирования!" });
        });
    },
    async logout() {
      await this.$auth.logout();
    },
    clearManagedUser() {
      this.$http.fillPolicy()
      this.$store.dispatch("user/setManagedUser");
      this.$router.push({ name: "employees" });
    },
  },
});
</script>

<style lang="scss" scoped>
.menu-root {
  @include flex(column, flex-start, stretch);
  background-color: $mo-var_main_panel-background-color;
  width: 250px;
  box-shadow: 2px 2px 5px 0px rgba(50, 50, 50, 0.75);
  text-align: left;
  > .logo {
    @include flex();
    padding: 10px;
  }
  > div {
    > .title {
      @include flex();
      padding: 10px 5px;
      &::before {
        content: "";
        flex: 1 1 auto;
        height: 2px;
        background-color: $mo-var_alt_text-color;
      }
      &::after {
        content: "";
        flex: 1 1 auto;
        height: 2px;
        background-color: $mo-var_alt_text-color;
      }
      > div {
        color: $mo-var_alt_text-color;
        padding: 5px;
        font-weight: bold;
      }
    }
    > .about {
      @include flex(column);
      color: $mo-var_alt_text-color;
      > div:not(:last-child) {
        padding-bottom: 5px;
      }
      & > .close,
      //& > .copylink,
      & > .signout {
        padding-top: 5px;
        color: #ffffff;
        font-weight: bold;
        @include flex();
        cursor: pointer;
        > svg {
          margin: 0 5px;
        }
        &:hover {
          color: darken(#ffffff, 30%);
          > svg {
            color: darken(#ffffff, 30%);
          }
        }
      }
    }
    > a {
      height: 40px;
      @include flex(row, space-between, center);
      padding: 5px;
      color: $mo-var_alt_text-color;
      text-decoration: none;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
      transition: background-color linear 0.2s;
      &::before {
        content: "";
        height: 30px;
        width: 3px;
        min-width: 3px;
        margin-right: 5px;
        background-color: $mo-var_main_el-active-color;
      }
      > span {
        flex: 1 1 auto;
      }
      > svg {
        flex: 0 0 auto;
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
          transition: opacity linear 0.2s;
          opacity: 0;
        }
      }
      &:hover {
        background-color: $mo-var_main_el-color;
      }
      &.router-link-active {
        background-color: $mo-var_main_el-active-color;
        box-shadow: -3px 2px 5px 0px rgba(50, 50, 50, 0.75);
        > svg:last-child {
          opacity: 1;
        }
      }
    }
  }
}
.copylink {
  color: #ffffff;
  padding-top: 10px;
  text-align: center;
  width: 250px;
  height: 45px;
  font-weight: bold;
  cursor: pointer;
  > svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: darken(#ffffff, 30%);
  }
}

.copylink:active {
  padding-top: 5px;
  text-align: center;
  width: 250px;
  height: 45px;
  font-size: 17px;
  > svg {
    width: 24px;
    height: 24px;
  }
}
</style>
