import { createStore } from 'vuex'
import user from './modules/user'
import ranges from './modules/ranges'
import policy from './modules/policy'


export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    ranges,
    policy,
  },
  strict: true
})
