const state = () => ({
    all: {}
  })
  
  // getters
  const getters = {
    getPolicy: state => {
      return state.all.policy
    }
  }
  
  // actions
  const actions = {
    async setPolicy(context, policy) {
      context.commit('SET_POLICY', policy)
    }
  }
  
  // mutations
  const mutations = {
    CLEAR_ALL(state) {
      state.all = {}
    },
    SET_POLICY(state, policy) {
      let new_policy={}
      let user_id = this.getters['user/getUser'].id
      let user_managed_id = this.getters['user/getManagedUser'].id

      Object.entries(policy).forEach(([key, value]) => {
        //console.log(`${key} ${value}`)
        if(value === 'allow') new_policy[key]=true;
        if(value === 'deny') new_policy[key]=false;
        if(value === 'own_id') new_policy[key]=(user_id === user_managed_id);
      });
      state.all.policy = new_policy
      //console.log(new_policy)

      
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  
  